$(document).on('keypress blur', '.contact__txt-area', function (e) {
  if (e.type != 'keypress') e.preventDefault();

  var that = $(this);

  if (e.type == 'keypress') {
    if ($(e.target).hasClass('contact__txt-area_error')) {
      if (validateField(that)) {
        that.removeClass('contact__txt-area_error');
      } else {
        that.addClass('contact__txt-area_error');
      }
    } else {
      return true;
    }
  }

  if (validateField(that) && !(that.hasClass('contact__txt-area_error'))) {
    that.removeClass('contact__txt-area_error');
  } else {
    that.addClass('contact__txt-area_error');
  }
})
