$(document).on('blur', '.contact__input, .contact__txt-area', function () {
  var that = $(this);
  var tagname = that[0].tagName.toLowerCase();

  if (that.prop('name') === 'website' || that.prop('required')) {
    if (validateField(that)) {
      if (tagname === 'textarea') {
        that.removeClass('contact__txt-area_error');
      } else {
        that.removeClass('contact__input-error');
      }
    } else {
      if (tagname === 'textarea') {
        that.addClass('contact__txt-area_error');
      } else {
        that.addClass('contact__input-error');
      }
    }
  }
});

function validateField(that) {
  var tVal = that.val();
  var tAttr = that.prop('type');

  if (that.prop('name') === 'website') {
    if (tVal.length == 0) {
      return true;
    }

    return validateURL(tVal);
  }

  if (!(tVal.length >= 2)) {
    return false;
  }

  if (tAttr == 'email') {
    return validateEmail(that.val())
  } else if (tAttr == 'tel') {
    return !that.empty();
  }
  return true;
}

function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function validateURL(textval) {
  var urlregex = /^(https?|http):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.([a-zA-Z]{2,9}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return urlregex.test(textval);
}
