$(window).on("load", function () {
  $(".loading").fadeOut().remove();
  $("body").removeClass("no-scroll");
});

// function initFreshChat() {
//   window.fcWidget.init({
//     token: "68c07003-334d-47a3-b2b1-83c05c4faa6c",
//     host: "https://wchat.eu.freshchat.com",
//     tags: ["agilepartners"],
//     siteId: "AGILE_PARTNERS",
//   });
// }
// function initialize(i, t) {
//   var e;
//   i.getElementById(t)
//     ? initFreshChat()
//     : (((e = i.createElement("script")).id = t),
//       (e.async = !0),
//       (e.src = "https://wchat.eu.freshchat.com/js/widget.js"),
//       (e.onload = initFreshChat),
//       i.head.appendChild(e));
// }
// function initiateCall() {
//   initialize(document, "freshchat-js-sdk");
// }
// window.addEventListener
//   ? window.addEventListener("load", initiateCall, !1)
//   : window.attachEvent("load", initiateCall, !1);
