$(document).on('change', '.contact__checkbox', function () {
  var checked = $('.contact__checkbox:checked');
  var checkboxes = $('.contact__checkbox');

  if (checked.length !== 0) {
    checkboxes.prop('required', false);
    $('.contact__h3-check_err').fadeOut();
  } else {
    checkboxes.prop('required', true);
  }
});
