$(document).ready(function (e) {
    $('.contact__submit').click(function (e) {
        var $form = $('.contact__form');
        $form.find('.contact__input[required]').each(submitFieldsValidation('contact__input-error'));
        $form.find('.contact__txt-area[required]').each(submitFieldsValidation('contact__txt-area_error'));
        if (!$form.find('input:checked.contact__checkbox').length) {
            $form.find('.contact__h3-check_err').fadeIn();
        }
    })
});

var submitFieldsValidation = function (errorClassName) {
    return function () {
        var that = $(this);

        if (validateField(that)) { // contact__input.js
            that.removeClass(errorClassName);
        } else {
            that.addClass(errorClassName);
        }

        if (validateField(that) && !(that.hasClass(errorClassName))) {
            that.removeClass(errorClassName);
        } else {
            that.addClass(errorClassName);
        }
    }
}
