var initCnt = 0;

$(document).on('click', '.pp-slider__nav-left, .pp-slider__nav-right, .pp-slider__tabs-itm', function (e) {
  e.preventDefault();
  var itmsLnght = $('.pp-slider__tabs-ind').eq(0).find('.pp-slider__ind-itm').length;
  var that = $(this);

  if (that.hasClass('pp-slider__nav-left')) {
    if (initCnt <= 0) {
      initCnt = itmsLnght;
    }
    initCnt--;
  } else if (that.hasClass('pp-slider__nav-right')) {
    if (initCnt >= itmsLnght - 1) {
      initCnt = 0;
    } else {
      initCnt++;
    }
  } else if (that.hasClass('pp-slider__tabs-itm')) {
    initCnt = $(this).index();
  }

  changeSlide(initCnt);
});

(function(){
  changeSlide(initCnt);
})();

function changeSlide(inx) {
  $('.pp-slider__tabs-ind').eq(0).find('.pp-slider__ind-itm').removeClass('pp-slider__ind-itm_active').eq(inx).addClass('pp-slider__ind-itm_active');
  $('.pp-slider__inner').eq(0).find('.pp-slider__itm').removeClass('pp-slider__itm_active').eq(inx).addClass('pp-slider__itm_active');
  $('.pp-slider__tabs').eq(0).find('.pp-slider__tabs-itm').removeClass('pp-slider__tabs-itm_active').eq(inx).addClass('pp-slider__tabs-itm_active');
}
