$(document).ready(function() {
  var contact = $('.contact');
  if (contact.length) {
    $('body,html').animate({
      scrollTop: contact.offset().top,
    });
  }
});

$(document).on('submit', '.contact__form', function(e) {
  e.preventDefault();

  var $this = $(this);
  var submitBtn = $this.find('[type="submit"]');

  onSubmitValidation($this);

  if (
    !$('.contact__txt-area_error').length &&
    !$('.contact__input-error').length
  ) {
    var data = $this.serialize();
  } else {
    return false;
  }

  if (data) {
    $.ajax({
      method: 'post',
      data: data,
      dataType: 'json',
      url: 'https://formspree.io/xbnbwjbx',
      beforeSend: function() {
        submitBtn.attr('disabled', 'disabled');
        $('input, textarea').attr('disabled', 'disabled');
      },
      error: function(err) {
        formPopup(
          'Oops, there is a problem',
          err.message || 'Uncaught error',
          1
        );
        $('*[disabled="disabled"]').removeAttr('disabled');
      },
      success: function() {
        if (formPopup()) {
          $this[0].reset();
          $('*[disabled="disabled"]').removeAttr('disabled');
        }
      },
    });
  }
});

function formPopup(label, message, err) {
  var popup = $('.popup-msg');

  if (label)
    popup
      .find('.popup-msg__label')
      .eq(0)
      .text(label);

  if (message)
    popup
      .find('.popup-msg__answer')
      .eq(0)
      .text(message);

  if (err === 1) {
    popup.addClass('popup-msg_err');
  } else if (popup.hasClass('popup-msg_err')) {
    popup.removeClass('popup-msg_err');
  }

  popup.addClass('popup-msg_show');

  setTimeout(function() {
    popup.removeClass('popup-msg_show');
  }, 4000);

  return true;
}

function onSubmitValidation(valForm) {
  var reqFields = $(valForm).find('[required]');

  for (var x = 0; x < reqFields.length; x++) {
    var $fld = $(reqFields[x]);

    if (!validateField($fld)) {
      if ($fld[0].tagName.toLowerCase() === 'textarea') {
        $fld.addClass('contact__txt-area_error');
      } else {
        $fld.addClass('contact__input-error');
      }
    }
  }
}
